import string from '../../../helpers/string_helper';
import Logger from '../../../helpers/logger';

const replaceSynonyms = (transcript) => {
  const synonymsOfThree = ['tree', 'free'];
  const synonymsOfFour = ['for'];

  const prepSynonymsOfThree = synonymsOfThree.map(
    (word) => `(^|\\s)${word}(\\s|$)`,
  );
  const prepSynonymsOfFour = synonymsOfFour.map(
    (word) => `(^|\\s)${word}(\\s|$)`,
  );

  const regex3 = new RegExp(prepSynonymsOfThree.join('|'), 'gi');
  const regex4 = new RegExp(prepSynonymsOfFour.join('|'), 'gi');

  return transcript
    .replace(regex3, ' three ')
    .replace(regex4, ' four ')
    .replace(/\s+/g, ' ')
    .trim();
};

const resumeOrPauseListening = (transcript, allowVoice) => {
  if (string.matches(transcript, 'pause listening')) {
    return false;
  }

  if (string.matches(transcript, 'resume listening')) {
    return true;
  }

  return allowVoice;
};

const shouldIgnoreTranscript = (transcript, lastVoiceRecognised) => {
  Logger.log({ current: transcript, prev: lastVoiceRecognised });

  const portionOfKnownFeedbackMessages = [
    'unrecognized',
  ];

  if (portionOfKnownFeedbackMessages.find((message) => transcript.includes(message))) {
    Logger.log(`[FEEDBACK REJECTED] "${transcript}"`);
    return true;
  }

  const partOfKnownInstructions = [
    'confirm ready to',
    'confirm you are',
    'enter package details',
    'go to',
    'locate order for packing',
    'no work',
    'nowhere',
  ];

  for (const instruction of partOfKnownInstructions) {
    if (transcript.includes(instruction)) {
      Logger.log(
        `[IGNORED, KNOWN INSTRUCTION CONTAINING COMMAND] "${transcript}"`,
      );
      return true;
    }
  }

  if (transcript === lastVoiceRecognised) {
    Logger.log(`[IGNORED, SAME AS LAST] "${transcript}"`);
    return true;
  }

  return false;
};

export { replaceSynonyms, resumeOrPauseListening, shouldIgnoreTranscript };
