import Logger from '../../../helpers/logger';
import integerValue from '../../../helpers/number_helper';
import fuzzySearch from '../store/fuzzy_search';
import InputExtractor from '../helpers/input_extractor';
import Store from './store';

class VoiceInput extends Store {
  call(params) {
    this.#initialize(params);

    return this.#processTranscript();
  }

  // private

  #initialize(params) {
    const { activeElement, inputHash, transcript } = params;

    this.activeElement = activeElement;
    this.inputHash = inputHash;
    this.transcript = transcript;
    this.extractor = new InputExtractor();

    fuzzySearch.init(this.inputHash);
  }

  #processTranscript() {
    return this.#instructions()
      .map((instruction) => this.#evaluate(instruction))
      .filter((output) => output !== null);
  }

  #instructions() {
    return this.extractor.call(
      this.transcript.split(' and '),
    );
  }

  #evaluate(instruction) {
    if (typeof instruction === 'number') {
      Logger.warn(`[Voice Input ✓] ${instruction}`);
      return this.#setValueOnDefaultElement(instruction);
    }

    if (typeof instruction === 'string') {
      Logger.warn(`[Voice Input ✓] "${instruction}"`);
      return this.#setValueOnSpecifiedLabel(instruction);
    }

    Logger.log(
      `[DISREGARDED INSTRUCTION] not number or string: ${instruction}`,
    );
    return null;
  }

  #setValueOnDefaultElement(value) {
    const element = this.#getDefaultElement();

    if (element) {
      Logger.warn(`[INPUT DETECTED ✓] "${this.activeElement.id}": "${value}"`);

      return {
        elementId: element.inputId,
        value,
        instruction: element.labelText,
      };
    }

    return null;
  }

  #getDefaultElement() {
    if (this.inputHash.length === 1) {
      return this.inputHash[0];
    }

    return this.inputHash.find(
      ({ inputId }) => inputId === this.activeElement.id,
    );
  }

  #setValueOnSpecifiedLabel(instruction) {
    const parts = instruction.split(' ');
    const { value } = integerValue(parts.shift());
    const numberPart = value;
    const labelPart = parts.join(' ').toLowerCase();

    const result = fuzzySearch.call(labelPart);

    if (result && numberPart !== null) {
      const { inputId } = result.item;

      Logger.warn(`[INPUT DETECTED ✓] ${inputId}: "${numberPart}"`);

      return {
        elementId: inputId,
        value: numberPart,
        instruction,
      };
    }

    Logger.log(`[INPUT ✗] ${instruction}`);
    return null;
  }
}

const voiceInput = new VoiceInput();

export default voiceInput;
