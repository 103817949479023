import Logger from '../../../helpers/logger';
import Store from './store';
import userSettings from './user_settings';

class Utterance extends Store {
  call(message, onEnd, onError) {
    Logger.warn(`[FEEDBACK ${userSettings.voiceSpeed()}] "${message}"`);

    return this.utterance(message, onEnd, onError);
  }

  // private

  utterance(message, onEnd, onError) {
    this.instance = new SpeechSynthesisUtterance(message);
    this.instance.rate = userSettings.voiceSpeed();
    this.instance.addEventListener('end', onEnd);
    this.instance.addEventListener('error', onError);

    return this.instance;
  }
}

const utterance = new Utterance();
export default utterance;
