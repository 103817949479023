import Logger from '../../../helpers/logger';
import speechProcessor from '../store/speech_processor';
import userSettings from '../store/user_settings';

const calculatedTranscript = (evt) => {
  const processAlternative = (alternative, idx) => {
    const transcript = alternative.transcript.trim();

    if (!transcript) {
      return null;
    }

    if (idx === 0) {
      Logger.warn('\n--- CALCULATING TRANSCRIPTS ---');
    }

    Logger.warn(`[ALTERNATIVE ${idx}] "${transcript}"`);

    if (
      transcript &&
      speechProcessor.evaluate(transcript, userSettings.allowVoice())
    ) {
      return transcript;
    }
  }

  const processResult = (result) => {
    for (let j = 0; j < result.length; j += 1) {
      const matchedTranscript = processAlternative(result[j], j);
      if (matchedTranscript) {
        return matchedTranscript;
      }
    }
  }

  for (let i = evt.resultIndex; i < evt.results.length; i += 1) {
    const matchedTranscript = processResult(evt.results[i]);
    if (matchedTranscript) {
      return matchedTranscript;
    }
  }

  return '';
};

export default calculatedTranscript;
