export default class Logger {
  static log(message, force = false) {
    if (!force) {
      return;
    }

    // These console logs are being allowed temporarily while we debug the voice pick project
    // Once everything is working and is stable, they can be removed completely
    // eslint-disable-next-line no-console
    console.log(message);
  }

  static warn(message) {
    Logger.log(message, true);
  }
}
