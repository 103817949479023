// app/javascript/controllers/sentry_controller.js
import { Controller } from '@hotwired/stimulus';
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  initialize() {
    this.initializeSentry();
  }

  initializeSentry() {
    Sentry.init({
      dsn: this.data.get('dsn'),
      environment: this.data.get('environment'),
      integrations: [
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    Sentry.setUser({
      email: this.data.get('userEmail'),
      name: this.data.get('userName'),
    });
  }
}
