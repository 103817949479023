import Fuse from 'fuse.js';
import Store from './store';

class FuzzySearch extends Store {
  static get defaultOptions() {
    return {
      includeScore: true,
      threshold: 0.4,
      keys: ['labelText'],
    };
  }

  init(data, options = {}) {
    this.fuse = new Fuse(
      data,
      Object.assign({}, FuzzySearch.defaultOptions, options),
    );
  }

  call(query) {
    return this.fuse.search(query)[0];
  }
}

const fuzzySearch = new FuzzySearch();

export default fuzzySearch;
