import Store from './store';

class UserSettings extends Store {
  allowVoice(value = null) {
    if (value !== null) {
      this.set('allow_voice', value);
    }

    return this.get('allow_voice');
  }

  voiceSpeed(value = null, defaultSpeed = 1) {
    if (value !== null) {
      this.set('voice_speed', value);
    }

    return this.get('voice_speed') || defaultSpeed;
  }
}

const userSettings = new UserSettings();
export default userSettings;
