import Logger from '../../../helpers/logger';
import Store from './store';
import calculatedTranscript from '../helpers/calculated_transcript';
import RecognitionEventProcessor from '../helpers/recognition_event_processor';

class SpeechRecognition extends Store {
  start() {
    return this.#initOnce();
  }

  stop() {
    if (this.recognition) {
      this.recognition.stop();
      this.recognition = null;
      Logger.log(`[SPEECH RECOGNITION TERMINATED]`);
      return true;
    }
    return false;
  }

  resume(eventCaller = '') {
    try {
      this.recognition.start();
      Logger.log(`[LISTENING RESUMED] ${eventCaller}`);
    } catch (error) {
      Logger.log(`[${eventCaller}] Error: ${error.message}`);
    }
  }

  // private

  #initOnce() {
    if (this.recognition) {
      return false;
    }

    this.#init();
    return true;
  }

  #init() {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    this.recognition = new SpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.lang = 'en-GB';
    this.recognition.maxAlternatives = 3;
    this.recognition.onresult = (evt) => (new RecognitionEventProcessor()).call(evt);
    this.recognition.onend = () => {
      Logger.log('[SPEECH RECOGNITION ENDED] Attempting to resume...');
      this.resume('sharedRecognition.onend');
    };
    this.recognition.onstart = () => {
      Logger.log('[LISTENING STARTED] sharedRecognition.onstart');
    };
    this.recognition.onerror = (event) => {
      const disregardError = ['aborted', 'no-speech'];
      if (disregardError.includes(event.error)) {
        return;
      }
      Logger.warn(`[SPEECH RECOGNITION ERROR] "${event.error}"`);
    };
    this.recognition.start();
  }
}

const speechRecognition = new SpeechRecognition();

export default speechRecognition;
